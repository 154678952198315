import React from 'react';
import { Blank } from 'grommet-icons';

const IconInfo = (props) => (
  <Blank viewBox="0 0 259.465517 256.051838" {...props}>
    <g
      transform="translate(-20.911793,277.625791) rotate(-360.00) scale(0.095238,-0.095238)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M1397 2913 c-15 -3 -62 -13 -105 -22 -476 -101 -869 -456 -1013 -915
  -79 -254 -79 -563 0 -807 159 -486 559 -831 1076 -929 115 -22 376 -16 501 10
  279 60 496 178 696 379 199 201 320 427 374 702 24 124 24 375 -1 491 -116
  541 -494 930 -1035 1066 -71 18 -128 23 -278 26 -104 2 -201 1 -215 -1z m344
  -131 c177 -22 367 -92 514 -188 269 -178 446 -426 528 -741 28 -109 39 -326
  21 -446 -77 -528 -484 -944 -1014 -1036 -127 -22 -386 -14 -498 16 -248 65
  -437 175 -605 350 -565 589 -402 1544 327 1916 229 118 474 161 727 129z"
      />
      <path
        d="M1528 2232 c-164 -40 -275 -169 -287 -332 l-5 -73 74 0 73 0 8 55 c8
  60 38 137 66 169 47 53 180 77 266 47 110 -37 175 -130 163 -232 -13 -113 -92
  -173 -239 -183 l-82 -6 0 -203 0 -203 68 0 68 0 0 130 0 130 47 7 c65 9 166
  62 206 108 57 65 77 122 77 223 0 75 -4 99 -28 149 -78 170 -283 262 -475 214z"
      />
      <path d="M1554 1092 l0 -84 79 0 79 0 0 84 0 84 -79 0 -79 0 0 -84z" />
    </g>
  </Blank>
);

export default IconInfo;
